import React, { Fragment } from 'react'
import Video10 from '../components/Video10'
import Video11 from '../components/Video11'
import Video12 from '../components/Video12'
import Pocketv from '../images/pocketv.svg'
import { Link } from "gatsby"
import Ios from '../images/ios.svg'
import Android from '../images/android.png'
import AppMobile from '../images/app/pocket-mobile.png'

import {
  inspired,
  container,
  contentView,
  columns, 
  column,
  visionBox,
  btnMoreVideo,
  titlesApp,
  titleApp,
  ctaApp,
  linksApps
} from './styles/EstudeAqui.styles'

import { 
  bg10,
  desktop,
  mobile,
} from '../components/styles/InternalPages.styles'

const InspiredSite = props => (
  <Fragment>
    <div css={inspired}>
      <div css={container}>
        <div css={visionBox}>
          <div css={columns}>
            <div css={column}>
              <div css={contentView}>
                <h2>
                  <span>O QUE É A</span><br />
                  OLIMPÍADA<br /> POCKET ?
                </h2>
                <p style={{ marginBottom: 36 }}>A Olimpíada Pocket é uma série de olimpíadas sobre temas específicos que acontecem ao longo do ano letivo: pautas da ordem do dia (como o COVID-19), comemorativas (como algum marco histórico) ou relevantes cultural e cientificamente tornam-se os protagonistas desses processos olímpicos pontuais. Grandes eventos, autores e obras clássicas da história, debates contemporâneos... todos estes temas são passíveis de se transformar em mais um processo de ensino e aprendizagem.</p>
                <p style={{ marginBottom: 36 }}>A Olimpíada Pocket busca disseminar conteúdos relevantes e boas práticas de ensino e aprendizagem fortalecendo a interação entre todos aqueles interessados em olimpíadas de conhecimento. Para tal, a Pocket está aberta a todas as escolas, de forma gratuita e virtual, ampliando a participação e a viabilidade da realização da olimpíada nas diferentes regiões do país. Os conteúdos das pockets são disponibilizados, também gratuitamente, para as escolas e professores, disseminando o ideal das olimpíadas de conhecimento e seus temas nas práticas de sala de aula. Todos os professores, gestores e coordenadores escolares são convidados a participar dos processos de elaboração, criação e realização das pockets. </p>
                <p><span>Participe você também!</span></p>
              </div>
            </div>
          </div>
          <div style={{ marginTop: 60, marginBottom: 32 }}>
            <img src={Pocketv} alt='Pocketv' />
          </div>
          <div css={columns}>
            <div css={column}>
              <div style={{ boxShadow: '0px 5px 10px rgba(0,0,0,0.2)', marginBottom: 36 }}>
                <Video10 />
              </div>
            </div>
            <div css={column}>
              <div style={{ boxShadow: '0px 5px 10px rgba(0,0,0,0.2)', marginBottom: 36 }}>
                <Video11 />
              </div>
            </div>
            <div css={column}>
              <div style={{ boxShadow: '0px 5px 10px rgba(0,0,0,0.2)', marginBottom: 36 }}>
                <Video12 />
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Link to='pocketv' css={btnMoreVideo}>VER TODOS OS VÍDEOS</Link>
          </div>
        </div>
      </div>
    </div>
    <section css={[bg10, desktop]} style={{ marginTop: 120, display: 'flex', flexDirection: 'column' }}>
      <div css={titlesApp} style={{ marginTop: '-100px' }}>
        <div css={ctaApp}>NOSSO APP</div>
        <div css={titleApp}>Baixe o app das Olimpíadas Pocket e <span>faça sua prova.</span></div>
      </div>
      <div css={linksApps}>
        <a href='https://apps.apple.com/br/app/olimp%C3%ADada-pocket/id1508377673' target='_blank' rel='noopener noreferrer'><img src={Ios} alt='IOs' /></a>
        <a href='https://play.google.com/store/apps/details?id=br.com.fractaltecnologia.pocket' target='_blank' rel='noopener noreferrer'><img src={Android} alt='Android' /></a>
      </div>
    </section>
    <section css={mobile} style={{ marginTop: 60, backgroundColor: '#EEEEEE', display: 'flex', flexDirection: 'column' }}>
      <img src={AppMobile} alt='/' />
      <div style={{ textAlign: 'center', margin: '60px 0' }}>
        <div css={ctaApp}>NOSSO APP</div>
        <div css={titleApp} style={{ marginBottom: 24, padding: '0 24px' }}>Baixe o app das Olimpíadas Pocket e <span>faça sua prova.</span></div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <a href='https://apps.apple.com/br/app/olimp%C3%ADada-pocket/id1508377673' target='_blank' rel='noopener noreferrer'><img src={Ios} alt='IOs' style={{ width: 200, marginBottom: 12 }} /></a>
          <a href='https://play.google.com/store/apps/details?id=br.com.fractaltecnologia.pocket' target='_blank' rel='noopener noreferrer'><img src={Android} alt='Android' style={{ width: 200 }} /></a>
        </div>
      </div>
    </section>
  </Fragment>
)

export default InspiredSite
