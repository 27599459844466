import { css } from '@emotion/core'
import Coronavirus from '../images/banner/coronavirus.png'
import Guerra from '../images/banner/2a-guerra.png'
import PosGuerra from '../images/banner/1984.png'
import Agro from '../images/banner/agro.png'
import Competicao2 from '../images/banner/1984-2.png'
import Hackeando from '../images/banner/hackeando.png'
import TeamSix from '../images/banner/bg-team-six.png'

export const PocketCoronavirus = css`
  background: url(${Coronavirus}) no-repeat;
  background-size: cover;
  background-position: center;
  height: 908px !important;
  align-items: center;
  justify-content: center;
  display: flex;
  font-family: 'Montserrat', sans-serif;
  h1 {
    font-style: normal;
    font-weight: 900;
    font-size: 42px;
    line-height: 44px;
    text-align: left;
    color: #FFFFFF;
    height: 160px;
  }
  h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 30px;
    width: 100%;
    img {
      margin-right: 16px;
    }
  }
  h3 {
    font-family: 'Montserrat', sans-serif;
    padding-top: 8px;
    padding-bottom: 8px;
    width: 100%;
    max-width: 313px;
    color: white;
    align-items: center;
    justify-content: center;
    display: flex;
    font-size: 16px;
    font-weight: bold;
    line-height: 22px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    background: #FFFFFF !important;
    border-radius: 36px;
    outline: none;
    margin-left: 0px;
    color: #2E9E93 !important;
    @media (min-width: 260px) and (max-width: 736px) {
      width: 328px;
    }
  }
  transition: 0.5s all ease;
  :hover {
    transform: scale(1.1);
    transition: 0.5s all ease;
  }
  @media (min-width: 260px) and (max-width: 736px) {
    height: 750px !important;
    :hover {
      transform: none;
    }
  }
`
export const PocketSegundGuerra = css`
  background: url(${Guerra}) no-repeat;
  background-size: cover;
  background-position: center;
  height: 908px !important;
  align-items: center;
  justify-content: center;
  display: flex;
  font-family: 'Montserrat', sans-serif;
  h1 {
    font-style: normal;
    font-weight: 900;
    font-size: 42px;
    line-height: 44px;
    text-align: left;
    color: #FFFFFF;
    height: 160px;
  }
  h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 30px;
    width: 100%;
    img {
      margin-right: 16px;
    }
  }
  h3 {
    font-family: 'Montserrat', sans-serif;
    padding-top: 8px;
    padding-bottom: 8px;
    width: 100%;
    max-width: 313px;
    color: white;
    align-items: center;
    justify-content: center;
    display: flex;
    font-size: 16px;
    font-weight: bold;
    line-height: 22px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    background: #FFFFFF !important;
    border-radius: 36px;
    outline: none;
    margin-left: 0px;
    color: #8C7A69 !important;
    @media (min-width: 260px) and (max-width: 736px) {
      width: 328px;
    }
  }
  transition: 0.5s all ease;
  :hover {
    transform: scale(1.1);
    transition: 0.5s all ease;
  }
  @media (min-width: 260px) and (max-width: 736px) {
    height: 750px !important;
    :hover {
      transform: none;
    }
  }
`
export const PocketGuerra = css`
  background: url(${PosGuerra}) no-repeat;
  background-size: cover;
  background-position: center;
  height: 908px !important;
  align-items: center;
  justify-content: center;
  display: flex;
  font-family: 'Montserrat', sans-serif;
  h1 {
    font-style: normal;
    font-weight: 900;
    font-size: 42px;
    line-height: 44px;
    text-align: left;
    color: #FFFFFF;
    height: 160px;
  }
  h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 30px;
    width: 100%;
    img {
      margin-right: 16px;
    }
  }
  h3 {
    font-family: 'Montserrat', sans-serif;
    padding-top: 8px;
    padding-bottom: 8px;
    width: 100%;
    max-width: 313px;
    color: white;
    align-items: center;
    justify-content: center;
    display: flex;
    font-size: 16px;
    font-weight: bold;
    line-height: 22px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    background: #FFFFFF !important;
    border-radius: 36px;
    outline: none;
    margin-left: 0px;
    color: #00078C !important;
    @media (min-width: 260px) and (max-width: 736px) {
      width: 328px;
    }
  }
  transition: 0.5s all ease;
  :hover {
    transform: scale(1.1);
    transition: 0.5s all ease;
  }
  @media (min-width: 260px) and (max-width: 736px) {
    height: 750px !important;
    :hover {
      transform: none;
    }
  }
`
export const PocketGuerra2 = css`
  background: url(${Competicao2}) no-repeat;
  background-size: cover;
  background-position: center;
  height: 908px !important;
  align-items: center;
  justify-content: center;
  display: flex;
  font-family: 'Montserrat', sans-serif;
  h1 {
    font-style: normal;
    font-weight: 900;
    font-size: 42px;
    line-height: 44px;
    text-align: left;
    color: #FFFFFF;
    height: 160px;
  }
  h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 30px;
    width: 100%;
    img {
      margin-right: 16px;
    }
  }
  h3 {
    font-family: 'Montserrat', sans-serif;
    padding-top: 8px;
    padding-bottom: 8px;
    width: 100%;
    max-width: 313px;
    color: white;
    align-items: center;
    justify-content: center;
    display: flex;
    font-size: 16px;
    font-weight: bold;
    line-height: 22px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    background: #FFFFFF !important;
    border-radius: 36px;
    outline: none;
    margin-left: 0px;
    color: #00078C !important;
    @media (min-width: 260px) and (max-width: 736px) {
      width: 328px;
    }
  }
  h4 {
    font-family: 'Montserrat', sans-serif;
    padding-top: 8px;
    padding-bottom: 8px;
    width: 100%;
    max-width: 313px;
    color: white;
    align-items: center;
    justify-content: center;
    display: flex;
    font-size: 16px;
    font-weight: bold;
    line-height: 22px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    background: #FFFFFF !important;
    border-radius: 36px;
    outline: none;
    margin-left: 0px;
    color: #9d1216 !important;
    @media (min-width: 260px) and (max-width: 736px) {
      width: 328px;
    }
  }
  transition: 0.5s all ease;
  :hover {
    transform: scale(1.1);
    transition: 0.5s all ease;
  }
  @media (min-width: 260px) and (max-width: 736px) {
    height: 750px !important;
    :hover {
      transform: none;
    }
  }
`
export const PocketAgro = css`
  background: url(${Agro}) no-repeat;
  background-size: cover;
  background-position: center;
  height: 908px !important;
  align-items: center;
  justify-content: center;
  display: flex;
  font-family: 'Montserrat', sans-serif;
  h1 {
    font-style: normal;
    font-weight: 900;
    font-size: 42px;
    line-height: 44px;
    text-align: left;
    color: #FFFFFF;
    height: 160px;
  }
  h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 30px;
    width: 100%;
    img {
      margin-right: 16px;
    }
  }
  h3 {
    font-family: 'Montserrat', sans-serif;
    padding-top: 8px;
    padding-bottom: 8px;
    width: 100%;
    max-width: 313px;
    color: white;
    align-items: center;
    justify-content: center;
    display: flex;
    font-size: 16px;
    font-weight: bold;
    line-height: 22px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    background: #FFFFFF !important;
    border-radius: 36px;
    outline: none;
    margin-left: 0px;
    color: #75A65F !important;
    @media (min-width: 260px) and (max-width: 736px) {
      width: 328px;
    }
  }
  transition: 0.5s all ease;
  :hover {
    transform: scale(1.1);
    transition: 0.5s all ease;
  }
  @media (min-width: 260px) and (max-width: 736px) {
    height: 750px !important;
    :hover {
      transform: none;
    }
  }
`

export const PocketHackeando = css`
  background: url(${Hackeando}) no-repeat;
  background-size: cover;
  background-position: center;
  height: 908px !important;
  align-items: flex-end;
  justify-content: center;
  display: flex;
  font-family: 'Montserrat', sans-serif;
  padding: 3em;
  h1 {
    font-style: normal;
    font-weight: 900;
    font-size: 42px;
    line-height: 44px;
    text-align: left;
    color: #FFFFFF;
    height: 160px;
  }
  h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 30px;
    width: 100%;
    img {
      margin-right: 16px;
    }
  }
  h3 {
    font-family: 'Montserrat', sans-serif;
    padding-top: 8px;
    padding-bottom: 8px;
    width: 100%;
    max-width: 313px;
    color: white;
    align-items: center;
    justify-content: center;
    display: flex;
    font-size: 16px;
    font-weight: bold;
    line-height: 22px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    background: #FFFFFF !important;
    border-radius: 36px;
    outline: none;
    margin-left: 0px;
    color: #75A65F !important;
    @media (min-width: 260px) and (max-width: 736px) {
      width: 328px;
    }
  }
  transition: 0.5s all ease;
  :hover {
    transform: scale(1.1);
    transition: 0.5s all ease;
  }
  @media (min-width: 260px) and (max-width: 736px) {
    height: 750px !important;
    :hover {
      transform: none;
      padding-bottom: 2em;
    }
  }
`


export const btn = css`
  font-family: 'Montserrat', sans-serif;
  width: 300px;
  padding-top: 24px;
  padding-bottom: 24px;
  margin-top: 120px;
  color: white;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 22px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  background: #2E9E93 !important;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  margin-left: 0px;
  a {
    color: #fff;
  }
  :hover {
    opacity: 0.9;
    transition: color 0.25s ease-out;
  }
  @media (min-width: 260px) and (max-width: 736px) {
    width: 328px;
    margin-top: 40px;
  }
`
export const btn2 = css`
  font-family: 'Montserrat', sans-serif;
  width: 300px;
  padding-top: 24px;
  padding-bottom: 24px;
  margin-top: 120px;
  color: white;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 22px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  background: #8C7A69!important;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  margin-left: 0px;
  a {
    color: #fff;
  }
  :hover {
    opacity: 0.9;
    transition: color 0.25s ease-out;
  }
  @media (min-width: 260px) and (max-width: 736px) {
    width: 328px;
    margin-top: 40px;
  }
`
export const btn3 = css`
  font-family: 'Montserrat', sans-serif;
  width: 300px;
  padding-top: 24px;
  padding-bottom: 24px;
  margin-top: 120px;
  color: white;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 22px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  background: #00078C !important;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  margin-left: 0px;
  a {
    color: #fff;
  }
  :hover {
    opacity: 0.9;
    transition: color 0.25s ease-out;
  }
  @media (min-width: 260px) and (max-width: 736px) {
    width: 328px;
    margin-top: 40px;
  }
`
export const btn4 = css`
  font-family: 'Montserrat', sans-serif;
  width: 300px;
  padding-top: 24px;
  padding-bottom: 24px;
  margin-top: 120px;
  color: white;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 22px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  background: #75A65F !important;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  margin-left: 0px;
  a {
    color: #fff;
  }
  :hover {
    opacity: 0.9;
    transition: color 0.25s ease-out;
  }
  @media (min-width: 260px) and (max-width: 736px) {
    width: 328px;
    margin-top: 40px;
  }
`
export const btn5 = css`
  font-family: 'Montserrat', sans-serif;
  width: 300px;
  padding-top: 24px;
  padding-bottom: 24px;
  margin-top: 120px;
  color: white;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 22px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  background: #9d1216 !important;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  margin-left: 0px;
  a {
    color: #fff;
  }
  :hover {
    opacity: 0.9;
    transition: color 0.25s ease-out;
  }
  @media (min-width: 260px) and (max-width: 736px) {
    width: 328px;
    margin-top: 40px;
  }
`

export const btn6 = css`
  font-family: 'Montserrat', sans-serif;
  width: 300px;
  padding-top: 24px;
  padding-bottom: 24px;
  margin-top: 120px;
  color: white;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 22px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  background: #022433 !important;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  margin-left: 0px;
  a {
    color: #fff;
  }
  :hover {
    opacity: 0.9;
    transition: color 0.25s ease-out;
  }
  @media (min-width: 260px) and (max-width: 736px) {
    width: 328px;
    margin-top: 40px;
  }
`


export const arrow = css`
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #FFFFFF;
  flex-direction: column;
  margin-top: 47px;
  img {
    margin-top: 28px;
  }
  @media (min-width: 260px) and (max-width: 736px) {
    margin-top: 24px;
    img {
      margin-top: 8px;
    }
  }
`

export const pocketContent = css`
  margin: 32px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  /* @media (min-width: 260px) and (max-width: 736px) {
    margin-top: -270px;
    margin-bottom: 0px;
  } */
`

export const pocketContent2 = css`
  margin: 32px;
  justify-content: left;
  align-items: left;
  display: flex;
  flex-direction: column;
  h1 {
     
      color: #fff;
    }
    h2 {
      color: #fff;

    }
  @media (max-width: 736px) {
    h1 {
      font-size: 50px;
      line-height: 57px;
      color: #fff;
    }
    h2 {
      color: #fff;
      font-size: 25px;
    }
  }
`

export const PocketTeamSix = css`
  background: url(${TeamSix}) no-repeat;
  background-size: cover;
  background-position: center;
  height: 908px !important;
  align-items: center;
  justify-content: center;
  display: flex;
  font-family: 'Montserrat', sans-serif;
  h1 {
    font-style: normal;
    font-weight: 900;
    font-size: 72px;
    line-height: 72px;
    text-align: left;
    color: #555555;
    margin-bottom: 24px;
    text-transform: uppercase;
  }
  h2 {
    color: #555555;
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 32px;
    font-weight: 700;
    line-height: 32px;
    text-align: left;
    text-transform: uppercase;
    span {
      font-weight: 500;
    }
  }
  h3 {
    font-family: 'Montserrat', sans-serif;
    padding-top: 8px;
    padding-bottom: 8px;
    width: 100%;
    max-width: 313px;
    color: white;
    align-items: center;
    justify-content: center;
    display: flex;
    font-size: 16px;
    font-weight: bold;
    line-height: 22px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    background: #FFFFFF !important;
    border-radius: 36px;
    outline: none;
    margin-left: 0px;
    color: #75A65F !important;
    @media (min-width: 260px) and (max-width: 736px) {
      width: 328px;
    }
  }
  transition: 0.5s all ease;
  
  @media (min-width: 260px) and (max-width: 736px) {
    height: 750px !important;
    :hover {
      transform: none;
    }
  }
`
export const btn7 = css`
  font-family: 'Montserrat', sans-serif;
  width: 300px;
  padding-top: 24px;
  padding-bottom: 24px;
  margin-top: 60px;
  color: white;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 22px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  background: #FF7843 !important;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  margin-left: 0px;
  a {
    color: #fff;
  }
  :hover {
    opacity: 0.9;
    transition: color 0.25s ease-out;
  }
  @media (min-width: 260px) and (max-width: 736px) {
    width: 328px;
    margin-top: 40px;
  }
`