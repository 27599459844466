import React, { Component } from 'react'
import { Global } from '@emotion/core'
import SEO from "../components/seo"
import * as Scroll from 'react-scroll'
import NavBar from '../components/NavBar'
import Banner from '../components/HeroBannerSite'
import EstudeAqui from '../components/EstudeAqui'
// import Professores from '../components/Professores'
// import ListaDeMaterial from '../components/ListaDeMaterial'
import Footer from '../components/Footer'

import { globalStyles } from '../components/layout.styles'



export const MenuContext = React.createContext({
  visible: false,
  setVisible: () => {}
})

class SitePage extends Component {
  constructor (props) {
    super(props)
    this.setVisible = (value) => {
      this.setState(({ visible: value }))
    }

    this.state = {
      visible: false,
      setVisible: this.setVisible
    }
  }

  render () {
    
    return (
      <div>
        <Global styles={globalStyles} />
        <SEO title="Olimpíada Pocket" />
        <MenuContext.Provider value={this.state}>
          <Scroll.Element
            id='header'
          >
            <NavBar location={this.props.location} /> 
          </Scroll.Element>
          <Banner />
          <Scroll.Element>
            <div id='a-olimpiada' style={{ display: 'block', position: 'relative', top: '-120px', visibility: 'hidden' }}></div>
            <EstudeAqui />
          </Scroll.Element>
          {/* <Scroll.Element>
            <div id='quem-participa' style={{ display: 'block', position: 'relative', top: '-120px', visibility: 'hidden' }}></div>
            <ListaDeMaterial />
          </Scroll.Element> */}
          {/* <Scroll.Element>
            <div id='professores' style={{ display: 'block', position: 'relative', top: '-120px', visibility: 'hidden' }}></div>
            <Professores />
          </Scroll.Element> */}
          <Footer />
        </MenuContext.Provider>
      </div>
    )
  }
}

export default SitePage
