import React, { Component } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { Link } from "gatsby"
import Calendar from '../images/calendar.svg'
import ArrowRight from '../images/arrowRight.png'
import Selo from '../images/selo.png'

import { 
  PocketCoronavirus,
  btn,
  PocketSegundGuerra,
  btn2,
  PocketGuerra,
  btn3,
  PocketAgro,
  btn4,
  btn5,
  btn6,
  btn7,
  arrow,
  pocketContent,
  pocketContent2,
  PocketGuerra2,
  PocketHackeando,
  PocketTeamSix
} from "./PocketCarousel.styles"

import {
  container,
} from './styles/EstudeAqui.styles'

export default class Responsive extends Component {
  render() {
    var settings = {
      dots: false,
      infinite: true,
      speed: 300,
      slidesToShow: 4,
      slidesToScroll: 1,
      initialSlide: 0,
      arrows: false,
      autoplay: false,
      autoplaySpeed: 3000,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 0,
            dots: true
          }
        }
      ]
    };
    return (
      <div style={{ marginTop: 100 }}>
        <div css={PocketTeamSix}>
          <div css={container}>
            <div css={pocketContent2}>
              <h1>Overlord 80</h1>
              <h2>Edição Comemorativa</h2>
              <h2>
                <span>aos 80 anos do&nbsp;</span> Dia D</h2>
              <h2 style={{ marginTop: 32 }}>15/04 a 06/06</h2>
              <a href='https://colegios.redebertoni.com.br/olimpiadaoverloard80' css={btn7}>SAIBA MAIS</a>
            </div>
          </div>
          <div style={{
                position: 'absolute',
                background: 'rgb(0 0 0 / 52%)',
                width: '100%',
                height: '100%'
          }}>
          </div>
        </div>
        <Slider {...settings}>
          <div>
           <Link to='/olimpiada-pocket-hackeando-ecossistemas'>
            {/* <div css={PocketHackeando} style={{ flexDirection: 'column', alignItems: 'center' }}> */}
            <div css={PocketHackeando}>
                <div css={pocketContent}>
                  <img src={Selo} alt='Selo' style={{ marginBottom: 330 }} />
                  <a href='https://fractalid.fractaltecnologia.com.br/login?app_id=29&url_redirect=https%3A%2F%2Fapp.olimpiadapocket.com.br%2F' css={btn6}>ACESSE A PROVA</a>
                  <Link to='/olimpiada-pocket-hackeando-ecossistemas'>
                    <div css={arrow}>
                      Saiba mais<br />
                      <img src={ArrowRight} alt='/' />
                    </div>
                  </Link>
                </div>
              </div>
           </Link>
          </div>
          <div>
           <Link to='/olimpiada-pocket-agrobusiness'>
            <div css={PocketAgro}>
                <div css={pocketContent}>
                  <img src={Selo} alt='Selo' style={{ marginBottom: 32 }} />
                  <h3>Agro</h3>
                  <h2><img src={Calendar} alt='calendar' /> 05/12 a 13/12</h2>
                  <h1>AGRO É POCKET<br /> AGRO É TECH, AGRO É TUDO</h1>
                  <a href='https://fractalid.fractaltecnologia.com.br/signup?app_id=29&url_redirect=https%3A%2F%2Fapp.olimpiadapocket.com.br%2F' css={btn4}>ACESSE A PROVA</a>
                  <Link to='/olimpiada-pocket-agrobusiness'>
                    <div css={arrow}>
                      Saiba mais<br />
                      <img src={ArrowRight} alt='/' />
                    </div>
                  </Link>
                </div>
              </div>
           </Link>
          </div>
          <div>
           <Link to='/competicao-cultural-1984'>
            <div css={PocketGuerra2}>
                <div css={pocketContent}>
                  <img src={Selo} alt='Selo' style={{ marginBottom: 32 }} />
                  <h4>Competição Cultural - 1984</h4>
                  <h2><img src={Calendar} alt='calendar' /> 05/12 a 13/12</h2>
                  <h1>PREPAREM SEUS<br /> DITÓGRAFOS!</h1>
                  <a href='https://fractalid.fractaltecnologia.com.br/signup?app_id=29&url_redirect=https%3A%2F%2Fapp.olimpiadapocket.com.br%2F' css={btn5}>ACESSE A PROVA</a>
                  <Link to='/competicao-cultural-1984'>
                    <div css={arrow}>
                      Saiba mais<br />
                      <img src={ArrowRight} alt='/' />
                    </div>
                  </Link>
                </div>
              </div>
           </Link>
          </div>
          <div>
            <Link to='/olimpiada-pocket-1984'>
              <div css={PocketGuerra}>
                <div css={pocketContent}>
                  <img src={Selo} alt='Selo' style={{ marginBottom: 32 }} />
                  <h3>1984 - George Orwell</h3>
                  <h2><img src={Calendar} alt='calendar' /> 09/10 a 25/10</h2>
                  <h1>O GRANDE IRMÃO<br /> ESTÁ ESPERANDO</h1>
                  <a href='https://fractalid.fractaltecnologia.com.br/signup?app_id=29&url_redirect=https%3A%2F%2Fapp.olimpiadapocket.com.br%2F' css={btn3}>Acesse a prova</a>
                  <Link to='/olimpiada-pocket-1984'>
                    <div css={arrow}>
                      Saiba mais<br />
                      <img src={ArrowRight} alt='/' />
                    </div>
                  </Link>
                </div>
              </div>
            </Link>
          </div>
          <div>
            <Link to='/olimpiada-pocket-segunda-guerra'>
              <div css={PocketSegundGuerra}>
                <div css={pocketContent}>
                  <img src={Selo} alt='Selo' style={{ marginBottom: 32 }} />
                  <h3>Segunda Guerra Mundial</h3>
                  <h2><img src={Calendar} alt='calendar' /> 02/09 a 20/09</h2>
                  <h1>NÓS QUEREMOS<br /> VOCÊ!</h1>
                  <a href='https://fractalid.fractaltecnologia.com.br/signup?app_id=29&url_redirect=https%3A%2F%2Fapp.olimpiadapocket.com.br%2F' css={btn2}>Acesse a prova</a>
                  <Link to='/olimpiada-pocket-segunda-guerra'>
                    <div css={arrow}>
                      Saiba mais<br />
                      <img src={ArrowRight} alt='/' />
                    </div>
                  </Link>
                </div>
              </div>
            </Link>
          </div>
          <div>
            <Link to='/olimpiada-pocket-coronavirus'>
              <div css={PocketCoronavirus}>
                <div css={pocketContent}>
                  <img src={Selo} alt='Selo' style={{ marginBottom: 32 }} />
                  <h3>Coronavírus</h3>
                  <h2><img src={Calendar} alt='calendar' /> 12 a 16/03</h2>
                  <h1>APURAR, CHECAR,<br /> APRENDER E INFORMAR</h1>
                  <a href='https://fractalid.fractaltecnologia.com.br/signup?app_id=29&url_redirect=https%3A%2F%2Fapp.olimpiadapocket.com.br%2F' css={btn}>Acesse a prova</a>
                  <Link to='/olimpiada-pocket-coronavirus'>
                    <div css={arrow}>
                      Saiba mais<br />
                      <img src={ArrowRight} alt='/' />
                    </div>
                  </Link>
                </div>
              </div>
            </Link>
          </div>
        </Slider>
      </div>
    );
  }
}
